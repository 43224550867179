.header {
  /* background-color: blue; */
  height: 10vh;

  justify-content: center;
  align-content: center;
}

.header div a {
  padding: 15px;
  text-decoration: none;
}

.aside1 {
  /* background-color: green; */
  font-size: 1rem;
}
.aside2 {
  /* background-color: green; */
  font-size: 1rem;
}

.main {
  /* background-color: blueviolet; */
  text-align: center;
  height: 70vh;
  font-size: 1rem;

  justify-content: center;
  align-content: center;
}

.footer {
  /* background-color: tomato; */
  height: 10vh;
  font-size: 1rem;
  text-align: center;

  justify-content: center;
  align-content: center;
}

.div {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  background-color: azure;
}

.div > * {
  padding: 2px;
  margin: 2px;
  flex: 1 100%;
  border-radius: 10px;
}

.li {
  list-style-type: none;
}

@media all and (min-width: 800px) {
  .main {
    flex: 3;
  }

  .aside1 {
    order: 1;
  }

  .aside2 {
    order: 2;
  }
  .main {
    order: 2;
  }
  .footer {
    order: 4;
  }
}

@media all and (min-width: 600px) {
  .aside {
    flex: 1;
  }
}

span {
  font-size: 20px;
}
