.divLogin {
  width: 100%;
  height: 100vh;
  /*  background-image: linear-gradient(to left,#42275a, #734b6d); */
  background-image: url("./images/fundoAutenticacao.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: none;

  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: "center";
}

.pLogin {
  padding: 20px;
  font-family: cursive;
  margin: auto;
}

.imgLogin {
  width: 5%;
  opacity: 0.6;
  cursor: pointer;
  margin: auto;
}
