.table {
    border-radius: 10px;
}

 .pergunta {
    width:max-content;
}

.resposta {
    display: flex;
    align-items: flex-start;
}

.comentario {
    width:max-content;
    
}

.titulo {
    cursor:pointer;
    padding: 10px;
    font-size: 1.4em;
    font-family: monospace;
}

#checkboxClassificacaoWelitonBarbosaSantos {
    display: none;
}

#conteudoClassificacaoWelitonBarbosaSantos {
    display: none;
}

#checkboxClassificacaoWelitonBarbosaSantos:checked ~ #conteudoClassificacaoWelitonBarbosaSantos {
    display: block;
}