.div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-size: 0.6rem;
}

.div img {
  object-fit: cover;
  max-width: 10%;
  border-radius: 50%;
  transition: transform 0.4s;
}

.div > * {
  padding: 15px;
}

.img {
  width: 6vw;
  border-radius: 50%;
  min-height: 6vh;
  object-fit: cover;

  transition: transform 0.4s;
  padding: 5px;
  margin-top: 5px;
}

.img:hover{
  transform: scale(1.1);
}

button  {
  background-color: hsl(0, 0%, 90%);
  color: darkslategrey;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  width: fit-content;

  border-bottom: 1px solid hsl(0, 0%, 70%);
}

button:hover {
  background-color: hsl(0, 0%, 75%);
}
