/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} */

/* body {
    background-color: #1e1e1e;
    color: #e1e1e1;
    text-align: center;
    display: flex; 
    justify-content: center; 
    flex-wrap: wrap;
    width: 100vw; 
    min-height: 100vh;   
} */

.container {
    background-color: #1e1e1e;
    color: #e1e1e1;
    text-align: center;
    display: flex; 
    justify-content: center; 
    flex-wrap: wrap;
    /* align-items: center; */
    /* margin: 20px; */
    
     margin: auto; 
    padding: 5px;
    width: 100%;
    border-radius: 10px; 
    /* min-height: 100vh; */

    /* width: 600px;
    padding-top: 100px; */
}

.container form label {
display: block;
margin-bottom: 0.8em;
font-weight: bold;
}

.container form input {
    color: inherit;
    background-color: rgba(255,255,255,0);
    padding: 0.2em;
    border: none;
    border-bottom: solid 2px rgba(255,255,255,0.2);
    border-radius: 10px;
    font-size: 2rem;
    /* font-weight: bold; */
    margin: auto;
    text-align: center;
    width: 80%;
    outline: none;
}

.container form input:focus {
    background-color: rgba(255,255,255,0.05);
}

.container .searchResults ul {
    overflow: hidden;
}

.container .searchResults ul li {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.5rem;
    color: rgba(255,255,255,0.5);
    list-style-type: '\2b50';

    position: relative;
    left: 100%;
    animation: direitaParaEsquerda 0.5s ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.container .searchResults ul li:hover {
    cursor: pointer;
    color: rgba(255,255,255,0.8);
    background-color: rgba(255,255,255,0.05);
}

.container .searchResults ul li span {
    padding-left: 1ch;
}

@keyframes direitaParaEsquerda {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}


/* //////////////////////////////////////////////////////// */

.table {
    border-radius: 10px;
}

 .pergunta {
    width:max-content;
}

.resposta {
    display: flex;
    align-items: flex-start;
}

.comentario {
    width:max-content;
    
}

.titulo {
    cursor:pointer;
    padding: 10px;
    font-size: 1.4em;
    font-family: monospace;
}

#checkboxBuscaPericia {
    display: none;
}

#conteudoBuscaPericia {
    display: none;
}

#checkboxBuscaPericia:checked ~ #conteudoBuscaPericia {
    display: block;
}

#checkboxBuscaCid {
    display: none;
}

#conteudoBuscaCid {
    display: none;
}

#checkboxBuscaCid:checked ~ #conteudoBuscaCid {
    display: block;
}

#checkboxBuscaSofrimentosPadecidosQualitativos {
    display: none;
}

#conteudoBuscaSofrimentosPadecidosQualitativos {
    display: none;
}

#checkboxBuscaSofrimentosPadecidosQualitativos:checked ~ #conteudoBuscaSofrimentosPadecidosQualitativos {
    display: block;
}

#checkboxBuscaBaremos {
    display: none;
}

#conteudoBuscaBaremos {
    display: none;
}

#checkboxBuscaBaremos:checked ~ #conteudoBuscaBaremos {
    display: block;
}

#checkboxBuscaDanoEsteticoPerezGarcia {
    display: none;
}

#conteudoBuscaDanoEsteticoPerezGarcia {
    display: none;
}

#checkboxBuscaDanoEsteticoPerezGarcia:checked ~ #conteudoBuscaDanoEsteticoPerezGarcia {
    display: block;
}

#checkboxBuscaDanoEsteticoClassSocFrancesaMedLegal {
    display: none;
}

#conteudoBuscaDanoEsteticoClassSocFrancesaMedLegal {
    display: none;
}

#checkboxBuscaDanoEsteticoClassSocFrancesaMedLegal:checked ~ #conteudoBuscaDanoEsteticoClassSocFrancesaMedLegal {
    display: block;
}

#checkboxBuscaDanoEsteticoAipeCobo {
    display: none;
}

#conteudoBuscaDanoEsteticoAipeCobo {
    display: none;
}

#checkboxBuscaDanoEsteticoAipeCobo:checked ~ #conteudoBuscaDanoEsteticoAipeCobo {
    display: block;
}

#checkboxBuscaNtepListaC {
    display: none;
}

#conteudoBuscaNtepListaC {
    display: none;
}

#checkboxBuscaNtepListaC:checked ~ #conteudoBuscaNtepListaC {
    display: block;
}

#checkboxBuscaTabelaDpvat {
    display: none;
}

#conteudoBuscaTabelaDpvat {
    display: none;
}

#checkboxBuscaTabelaDpvat:checked ~ #conteudoBuscaTabelaDpvat {
    display: block;
}

#checkboxBuscaValoracaoDanoMao {
    display: none;
}

#conteudoBuscaValoracaoDanoMao {
    display: none;
}

#checkboxBuscaValoracaoDanoMao:checked ~ #conteudoBuscaValoracaoDanoMao {
    display: block;
}

#checkboxBuscaCboMini {
    display: none;
}

#conteudoBuscaCboMini {
    display: none;
}

#checkboxBuscaCboMini:checked ~ #conteudoBuscaCboMini {
    display: block;
}

#checkboxBuscaCnaeMini {
    display: none;
}

#conteudoBuscaCnaeMini {
    display: none;
}

#checkboxBuscaCnaeMini:checked ~ #conteudoBuscaCnaeMini {
    display: block;
}