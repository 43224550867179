.header {
  background-color: hsl(0, 0%, 75%);
  height: 15vh;

  justify-content: center;
  align-content: center;

  font-size: 0.6rem;
  /* height: 1px; */
}

.aside1 {
  background-color: hsl(0, 0%, 80%);
  font-size: 0.6rem;
  /* max-width: 20%; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

.aside2 {
  background-color: hsl(0, 0%, 95%);
  font-size: 0.6rem;
  max-width: 1px;
  overflow-x: hidden;
}

.main {
  background-color: hsl(0, 0%, 95%);
  text-align: center;
  /* height: 75vh; */
  font-size: 1rem;

  justify-content: center;
  align-content: center;
  overflow-y: scroll;

  max-height: 80vh;
  min-width: 80vw;

  /* display: flex; */
}

.main fieldset {
  border-radius: 10px;
  font-size: 0.6rem;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 1px;
  margin: 1px;
  flex: 1 100%;
  display: flex;
  
}

.main fieldset legend {
  font-size: 0.6rem;
  border-radius: 10px;
  background-color: hsl(0, 0%, 80%);
   margin: auto; 
}

.main fieldset div {
  flex-direction: column;
  width: 100%;
}

.main fieldset div p{
  
  width: 100%;
}

table {
  width: 100%;
}

td > * {
  width: 95%;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  padding: 3px;
  margin: 3px;
}

td div > * {
  font-size: 0.6rem;
  width: 95%;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  padding: 3px;
  margin: 3px;
}

td label {
  display: flex;
  flex-direction: row;
}

td label > * {
  font-size: 0.6rem;
  border: none;
  padding: 1px;
  margin: 1px;
}

td label div {
  width: 5%;
}

td label span {
  width: 95%;
  text-align: justify;
}

table td button  {
  background-color: hsl(0, 0%, 90%);
  color: darkslategrey;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  width: fit-content;

  border-bottom: 1px solid hsl(0, 0%, 70%);
}

table td button:hover {
  background-color: hsl(0, 0%, 75%);
}

.footer {
  background-color: hsl(0, 0%, 95%);
  max-height: 1vh;
  font-size: 0.6rem;
  text-align: center;

  justify-content: center;
  align-content: center;
}

.div {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  background-color: azure;

  overflow-x: hidden;
  overflow-y: hidden;
}

.div > * {
  padding: 1px;
  margin: 1px;
  flex: 1 100%;
  border-radius: 10px;
}

.li {
  list-style-type: none;
}

@media all and (min-width: 800px) {
  .main {
    flex: 3;
    max-height: 80vh;
    min-width: 80vw;
  }
  .aside1 {
    order: 1;
    /*  overflow-y: scroll; */
    max-height: 80vh;
  }
  .aside2 {
    order: 2;
  }
  .main {
    order: 2;
  }
  .footer {
    order: 4;
    max-height: 1vh;
  }
}

@media all and (min-width: 600px) {
  .aside1 {
    flex: 1;
    max-width: 100%;
    max-height: 80vh;
  }
  .footer {
    max-height: 1vh;
  }
  .main {
    max-height: 80vh;
    min-width: 80vw;
  }
}

@media all and (min-width: 360px) {
  .aside1 {
    flex: 1;
    max-width: 100%;
    max-height: 80vh;
  }
  .footer {
    max-height: 1vh;
  }
  .main {
    max-height: 80vh;
    min-width: 80vw;
  }
}

/* .custom-input {
  
  input {
    
  }
  label {
    
  }
} */
