* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: hsl(0, 0%, 95%);
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}
