.dropdown button {
  background-color: hsl(0, 0%, 80%);
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;

  border-bottom: 1px solid hsl(0, 0%, 70%);
}

.dropdown:hover button {
  background-color: hsl(0, 0%, 70%);
}

.dropdown a {
  display: block;
  color: black;
  text-decoration: none;
  padding: 10px 15px;
}

.dropdown a:hover {
  background-color: hsl(0, 0%, 90%);
  border-radius: 10px;

  border-bottom: 1px solid hsl(0, 0%, 70%);
}

.dropdown .conteudo {
  display: none;
  position: absolute;
  background-color: hsl(0, 0%, 95%);
  box-shadow: 2px 2px 5px hsla(0, 0%, 0%, 0.8);

  border-radius: 10px;
  width: 19vw;
}

.dropdown:hover .conteudo {
  display: block;
  border-radius: 10px;
  width: 19vw;

  align-items: center;

  max-height: 50vh;
  overflow-y: scroll;
}

.dropdown {
  display: inline-block;
}

@media all and (min-width: 800px) {
  .dropdown:hover .conteudo {
    max-width: 19vw;
    max-height: 50vh;
    overflow-y: scroll;
  }
}

@media all and (min-width: 600px) {
  .dropdown:hover .conteudo {
    max-width: 19vw;
    max-height: 50vh;
    overflow-y: scroll;
  }
}

@media all and (min-width: 360px) {
  .dropdown:hover .conteudo {
    width: 100%;
    /* max-width: 100%; */
    max-height: 50vh;
    overflow-y: scroll;
  }
}
