.div {
    flex-direction: row;
    justify-content: center;
  }

.button {
  font-size: 0.6rem;
  border-radius: 10px;
  margin: 3px;
  padding:2px;
}

.iframe {
  width: 100%;
  height: 70vh;
  border-radius: 10px;
  margin: auto;
  border: none;
}

/* 
@media all and (min-width: 800px) {
    .iframe {
     margin: auto;
    }
  }
  
  @media all and (min-width: 600px) {
    .iframe {
        margin: auto;
       }  
  }
  
  @media all and (min-width: 360px) {
    .iframe {
        margin: auto;
       }
  }
  
 */