.table {
    border-radius: 10px;
}

 .pergunta {
    width:max-content;
}

.resposta {
    display: flex;
    align-items: flex-start;
}

.comentario {
    width:max-content;
    
}

.titulo {
    cursor:pointer;
    padding: 10px;
    font-size: 1.4em;
    font-family: monospace;
}

#checkboxAdmin1 {
    display: none;
}

#conteudoAdmin1 {
    display: none;
}

#checkboxAdmin1:checked ~ #conteudoAdmin1 {
    display: block;
}